
import { productDataOne } from "../spirits/data"
import Spirits from "../spirits";
import React,{useState} from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar';



const Spirit = () => {  const [isOpen, setIsOpen] = useState(false);

    const toggle= () =>{ setIsOpen(!isOpen);
    };
    


   

    return (
    <>
        <Navbar toggle={toggle}/>
        <Sidebar isOpen={isOpen} toggle={toggle}/>


        <Spirits heading='Our Selection of Spirits' data={productDataOne} />
      
       
    </>
  )
}

export default Spirit

