import React from 'react'
import Hero from '../Hero'
import Products from '../Products'
import Feature from '../Feature'

import { productData, productDataTwo,productDataThree, productDataFour,productDataFive} from '../Products/data'
import { FeatureData,FeatureData_two, FeatureData_three,FeatureData_four} from '../Feature/data'

const Home = () => {
 
  return (
    <>

    <Hero/>
    <Products heading='Irish Gins' data={productData}/>

    <Feature {...FeatureData}/>


    <Products  data={productDataTwo}/>

    

    <Feature {...FeatureData_four}/>

    <Products  data={productDataFive}/>

    <Feature {...FeatureData_two}/>
    
    <Products  data={productDataFour}/>

    <Feature {...FeatureData_three}/>
    
    <Products  data={productDataThree}/>

    
    </>
  )
}

export default Home