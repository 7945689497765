import styled from 'styled-components';
import Mallorca from '../../images/PalmaRange.jpg';
import AusWines from '../../images/winefarm.jpg';
import Latin from '../../images/drinklong.jpg';
import IrelandWhiskey from '../../images/barrels.jpeg';
import { Link } from 'react-router-dom';

export const FeatureContainer = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
  ${(props) => props.current === 'Mallorca' ? `url(${Mallorca})` : props.current === 'AusWines' ? `url(${AusWines})` : props.current === 'IrelandWhiskey' ? `url(${IrelandWhiskey})`: `url(${Latin})`};

    
  height: 100vh;
  max-height: 500px;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 0 1rem;

  h1 {
    font-size: clamp(2rem, 5vw, 5rem);
    color: #fff;
  }

  p {
    
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
  }
`;

export const Headerwrap= styled.div`
  font-size: clamp(2rem, 5vw, 5rem);
  color: #fff;
  padding-bottom: 10px;


`;



export const FeatureButton = styled(Link)`
  font-size: 1.4rem;
  margin-top: 2rem;
  padding: 0.6rem 3rem;
  border: none;
  background: #ECDBBA;
  color: #2D4263;
  transition: 0.2s ease-out;
  text-decoration:none;
  font-weight: bold;

  &:hover {
    color: #fff;
    background: #ECDBBA;
    transition: 0.2s ease-out;
    cursor: pointer;
  }
`;



