import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';


const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0.9vh;

  align-items: center;
`;

const ModalWrapper = styled.div`
  width: 70vh;
  height: 60vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  font-size: clamp(0.5rem,2vh,1rem);
 

  @media screen and (max-width: 555px){
    grid-template-columns: 1fr 1fr;
    width: 50vh;
    height: 60vh;
    display:grid;
    font-size: clamp(0.5rem,1.5vh,1rem);
    
    
  }

`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;


`;


const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
 

  color: #141414;
  p {
    margin-bottom: 1rem;
  }

`;

export const ProductsHeading = styled.h1`

  text-align: center;
  margin-bottom: 0.2rem;
  font-weight:200;

`;

export const ProductsSubHeading = styled.h1`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0.2rem;
  font-weight:200;
  font-style:italic;
  
`;

export const ProductInfo = styled.div`
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: left;
  min-width: 300px;
  
`;

export const TopLine = styled.p`
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 600;


`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;
export const ProductButton = styled.button`
  
  width: 10vh;
  font-size: 1rem;
  padding: 1rem 1rem;
  border: none;
  background: #C84B31;
  color: #fff;
  transition: 0.2 ease-out;
  
  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;


export const Modal = ({ showModal, setShowModal,modalData}) => {

  

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  });


  

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        console.log('I pressed');
      }
    },
    [setShowModal, showModal]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    
    <>
    
      {showModal ? (
        
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
                <ModalImg src={modalData.img} alt='camera' />
                
              <ModalContent>
                <ProductsHeading> {modalData.name} </ProductsHeading>
                <ProductsSubHeading>{modalData.size},{modalData.abv} </ProductsSubHeading>
                  <ProductInfo>
                      <TopLine> Description </TopLine>
                          {modalData.desc}
                          
                      <TopLine> Tasting Notes </TopLine>
                      
                          {modalData.aroma}
                          <br/>
                          {modalData.taste}

                  </ProductInfo>
                <ProductButton onClick={() => openInNewTab(modalData.link)}> {modalData.button}</ProductButton>
              </ModalContent>
              <CloseModalButton
                aria-label='Close modal'
                onClick={() => setShowModal(prev => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
 );
};