import product1 from '../../images/Sixling2.png';
import product2 from '../../images/GunPowderGin_1.png';
import product3 from '../../images/GunPowderGin_2.png';
import product4 from '../../images/PalmaGin_1.png';
import product5 from '../../images/PalmaGin_2.png';
import product6 from '../../images/PalmaVodka.png';
import product7 from '../../images/Egan.png';
import product8 from '../../images/Coco1.png';
import product9 from '../../images/Coco2.png';
import product10 from '../../images/Fuba.png';
import product11 from '../../images/RRed.png';
import product12 from '../../images/RRDry.png';
import product13 from '../../images/RRWildRose.png';
import product14 from '../../images/RRLivelyWhite.png';
import product15 from '../../images/drumW.png';


  export const productDataOne = [  {
    img: product2,
    alt: 'Gin',
    name: 'Drumshanbo Irish Gin ',
    aroma:'Aroma; Citrus, star anise',
    taste:'Taste; Citrus with grapefruit, coriander, warm spice and linger of Gunpowder tea',
    desc:
      'Drumshanbo gunpowder Irish Gin- Slow distilled by hand with oriental botanicals at The Shed Distillery in Drumshanbo, Ireland, and vapour infused with red grapefruit, lemon, lime and gunpowder green tea',
    price: 'NOK 625',
    button: 'Order',
    size: '- 70cl',
    abv: '43%',
    link: "https://www.vinmonopolet.no/Land/Irland/Drumshanbo-Gunpowder-Irish-Gin/p/13084901"
  },
  
  {
    img: product3,
    alt: 'Gin',
    name: 'Drumshanbo Irish Gin Sardinian Citrus',
    aroma:'Aroma; Citrus, fruity',
    taste:'Taste; fruity and zesty Sardinian citrus and linger of Gunpowder tea',
    desc:
      'Same receipe as the original but vapour infuses Sardinian "La Pompia" lemon, giving a fresher fruitier flavour',
    price: 'NOK 625',
    button: 'Order',
    size: '- 70cl',
    abv: '43%',
    link: "https://www.vinmonopolet.no/Nettbutikk-kategorier/Brennevin/Gin/Drumshanbo-Gunpowder-Irish-Gin-Sardinian-Citrus/p/14408701"
  },
    {
      img: product4,
      alt: 'Gin',
      name: 'Palma Gin',
      aroma:'Aroma; Fresh pine, orange and lavender floral notes',
      taste:'Taste; Citrus woody to sweet with honeyed floral notes ',
      desc:
        'Fresh Mediterranean/ Spanish gin distilled in small batches at Mallorca Distillery in Palma, with seven botanicals taken from the island. Vapour infused with lavender, lemon peel, orange peel, and tomato branches',
      price: 'NOK 630',
      size: '- 70cl',
      abv: ' 46,6%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Gin/p/13724701"
   
      
    },
    {
      img: product5,
      alt: 'Gin',
      name: 'Palma Spiced Gin',
      aroma:'Aroma; Cinnamon and orange peel aroma, with cloves',
      taste:'Taste; Mixed spice and vanilla with light citrus and oaked wine notes',
      desc:
        ' Same receipe as the original but matured in Mallorcan oak wine casks for 3 months with vanilla, cloves and cinnamon',
      price: 'NOK 660',
      size: '- 70cl',
      abv: ' 40,4%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Spiced-Gin/p/13725201",
      
    },
    {
      img: product1,
      alt: 'Gin',
      name: 'Sixling Irish Gin',
      aroma:'Aroma; Floral with juniper and elderflower notes',
      taste: 'Taste; herbal with juniper, elderflower and hint of cardamom',
      desc:
        'A fruity London Dry with taste of Junipers, elderflower and mulberries what is this word hey need longer',
      price: 'NOK 630',
      button: 'Order',
      size: '- 70cl',
      abv: '41,7%',
      link: "https://www.vinmonopolet.no/Land/Irland/Sixling-Irish-Gin/p/13300901",
      
    },
   {
      img: product6,
      alt: 'Palma Vodka',
      name: 'Palma Vodka',
      aroma:'Aroma; Vanilla and pepperkorn notes',
      taste:'Taste; sweet with hints of lemon peel and salty finish',
      desc:
        'Smooth and citrus premium vodka, infused with Flor de Sal sea salt from Es Trenc beach',
      price: 'NOK 625',
      size: '- 70cl',
      abv: ' 40%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-vodka/p/13725701"
    },
    {
      img: product7,
      alt: 'Egan',
      name: 'Egan´s Whiskey',
      aroma:'Aroma; heavy sherry, nuts, marzipan',
      taste:'Taste; sherry, mixed fruit, sultanas ',
      desc:
        'Egan´s Fortitude Single Malt Irish Whiskey is Triple distilled and non- chilled filtered, then matured in Pedro Ximenez sherry casks for 4- 5 years',
      price: 'NOK 625',
      size: '- 70cl',
      abv: ' 46%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Egan%27s-Fortitude-Single-Malt/p/13726801"
    },{
      img: product15,
      alt: 'Drum Whiskey ',
      name: 'Drumshanbo Irish Whiskey',
      aroma:'Aroma; aromatic warm pot spice, vanilla, dried fruit',
      taste:'Taste;Spice spice, fit, Oloroso sherry, and toasted oak notes',
      desc:
        'Drumshanbo Single Pot Still Irish Whiskey- Triple distilled by hand at the Shed Distillery and matured in Oloroso Sherry and ex bourbon casks for 5 years',
      price: 'NOK 625',
      size: '- 70cl',
      abv: ' 43%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Egan%27s-Fortitude-Single-Malt/p/13726801"
    },{
      img: product9,
      alt: 'Cocalero',
      name: 'Cocalero',
      aroma:'Aroma; Orange zest, mint, honey',
      taste:'Taste; Juniper, sweet tea, lime, mint and a hint of anise ',
      desc:
        'Coca leaf herbal spirit, with roots in the Colombian Andes, steam distilled with 17 botanicals including Guarana, Ginger, Lavender and Juniper ',
      price: 'NOK 625',
      size: '- 70cl',
      abv: ' 29%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Cocalero-Cl%C3%A1sico/p/13726901"
    },{
      img: product8,
      alt: 'Cocalero negro',
      name: 'Cocalero negro',
      aroma:'Aroma; Pepper, orange',
      taste:'Taste; Spicy pepper, bitter orange  ',
      desc:
        'Similar recipe to Cocalero Clasico but blended with the Aji Panca chili as a key botancial and the spicy Locoto pepper',
      price: 'NOK 625',
      size: '- 70cl',
      abv: ' 29%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Cocalero-Negro/p/13726701"
    },{
      img: product10,
      alt: 'Fuba',
      name: 'Fuba',
      aroma:'Aroma;  floral, with maize and citrus peel',
      taste:'Taste; subtle liquorice, almond and green bananas',
      desc:
        'Double distilled for a drier smoother taste than traditional cachacas',
      price: 'NOK 625',
      size: '- 70cl',
      abv: ' 40%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Brasil/Fub%C3%A1-Cacha-a/p/13726201"
    }
    ,{
      img: product11,
      alt: 'Regal Rogue Bold Red ',
      name: 'RR Bold Red',
     
      taste:'Semi-dry; aromatisk spice and dried fruit. Sugar 80g/L',
      desc:
        'Organic Shiraz and organic un wooded Chardonnay with Pepper berry. Wattene seed, thyme, cinnamon, clove, orange, dried cherry ',
      price: 'NOK 625',
      size: '- 50cl',
      abv: ' 16.5%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Australia/Regal-Rogue-Bold-Red-Vermouth/p/13726401"
    }
    ,{
      img: product12,
      alt: 'Regal Rogue Daring Dry ',
      name: 'RR Daring Dry ',
     
      taste:'Extra-dry; savoury and unami. Sugar 25g/L',
      desc:
        'Organic Sauvignon Blanc with Anise Myrtle, Quandong, and Thyme. White pepper, olive leaf, juniper ',
      price: 'NOK 625',
      size: '- 50cl',
      abv: ' 18%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Australia/Regal-Rogue-Daring-Dry-Vermouth/p/13726602"
    }    ,{
      img: product13,
      alt: 'Regal Rogue Wild Rose ',
      name: 'RR Wild Rose',
     
      taste:'Semi-dry; tropical fruit and fruit spice. Sugar 70g/L',
      desc:
        'Organic Cabernet Rose with Illawarra plums, Rosella, Strawberry gum',
      price: 'NOK 625',
      size: '- 50cl',
      abv: ' 16.5%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Australia/Regal-Rogue-Wild-Rose-Vermouth/p/13726502"
    }
    ,{
      img: product14,
      alt: 'Regal Rogue Lively White ',
      name: 'RR Lively White',
      taste:'Semi dry; floral, citrus. Sugar 80g/L',
      desc:
        'Organic un wooded Chardonnay with local Lime Myrtle. Desert Lime, native thyme, elderflower, chamomile ',
      price: 'NOK 625',
      size: '- 50cl',
      abv: ' 16.5%',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Australia/Regal-Rogue-Lively-White-Vermouth/p/13726302"
    },
    
  ];



