import styled from 'styled-components';

export const ProductsContainer = styled.div`
  /* width: 100vw; */
  min-height: 50vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #191919;
  color: #fff;
  
`;

/*/ #150f0f vs #3d4c32 /*/
export const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  
`;

export const ProductCard = styled.div`
  margin: 0 2rem;
  line-height: 2;
  width: 300px;
  
`;

export const ProductImg = styled.img`
  height: 500px;
  min-width: 300px;
  max-width: 100%;
  box-shadow: 8px 8px #ECDBBA;
  padding: 0.5rem;
  
`;

/*/ #fdc500 vs #3d4c32  vs #F1E5AC/*/

export const ProductsHeading = styled.h1`
  font-size: clamp(1rem, 1.5vw, 2rem);
  text-align: center;
  margin-bottom: 2rem;
  
`;

export const ProductTitle = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
  color:#FFF;
  
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  min-width: 300px;

  
`;

export const ProductDesc = styled.p`
  margin-bottom: 1rem;
  color: #2D4263;
  
`;

export const ProductTasteHeading= styled.p`

  margin-bottom: 1rem;
  font-size:1.2rem;
  font-weight:600;
  color: #2D4263;
  text-transform: uppercase;
 
`;


export const ProductTaste= styled.p`
  margin-bottom: 1rem;
  
  color:#2D4263;
  
  
 
`;

export const ProductButton = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1rem 4rem;
  border: none;
  background: #ECDBBA;
  color: #2D4263;
  transition: 0.2 ease-out;
  
  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;


export const TestSection = styled.div`



`;

/*/ #191919vs #2D4263  vs #C84B31 vs #ECDBBA/*/