



export const FeatureData = 
    {
      img: "Mallorca",
      HeadingText:'Mallorcan Gins and Vodka',
      Ptetxt: "Distilled at Mallorca Distillery in Palma",
    
      
    }

export const FeatureData_two = 
    {
      img: "AusWines",
      HeadingText:'Regal Rogue Vermouths',
      Ptetxt: "Made with Australian organic wines and wild botanicals",
    
      
    }

export const FeatureData_three = 
    {
      img: "Latin",
      HeadingText:'Latin Spirits',
      Ptetxt: "Versatile spirits from Brazil and Colombia",
    
      
    }

    export const FeatureData_four = 
    {
      img: "IrelandWhiskey",
      HeadingText:'Irish Whiskeys',
      Ptetxt: "Premium Whiskeys from Ireland",
    
      
    }
   
   
   