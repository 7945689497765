import React,{useState} from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar';
import {
    HeroContainer,
    HeroContent,
    HeroItems,
    HeroH1,
    HeroP,

  } from './HeroElements';

const Hero = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle= () =>{ setIsOpen(!isOpen);
    };

    return (
    <HeroContainer>
        <Navbar toggle={toggle}/>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContent>
            <HeroItems>
                <HeroH1>In Good Spirits</HeroH1>
                <HeroP> Premium spirits sourced globally for Vinmonopolet and HoReCa in Norway</HeroP>
            </HeroItems>
        </HeroContent>

    </HeroContainer>
    )
    }

    export default Hero