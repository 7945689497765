import React,{useState} from 'react';
import {
  ProductsContainer,
  ProductWrapper,
  ProductsHeading,
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductButton,
  
} from './SpiritElements';

import { Modal } from './Modal';

const Spirits = ({ heading, data }) => {
 
  

  const [showModal, setShowModal] = useState(false);  
  const [modalData, setModalData] = useState(null);



  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <ProductWrapper>
        {data.map((product, index) => {
          return (
            
            <ProductCard key={index}>
              <ProductImg src={product.img} alt={product.alt} />
              <ProductInfo>
                <ProductTitle>{product.name}</ProductTitle>
                <ProductButton onClick={()=>{
                  setShowModal(true);
                  setModalData(product);
                
               
                  console.log(modalData);
                  console.log(showModal);
                  
                 
                }}> Learn More!

                </ProductButton>
                
              </ProductInfo>

    
              
              
            </ProductCard>
           
            
          );
        })}
        <Modal modalData={modalData} showModal={showModal} setShowModal={setShowModal}>
        </Modal>



      </ProductWrapper>
    </ProductsContainer>
  );
};

export default Spirits;