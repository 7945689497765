import React from 'react';
import{Nav,NavIcon,NavLink,Bars,Logo} from'./NavbarElemnts';




const Navbar = ({ toggle }) => {
  return (
    <>
        <Nav>
          <Logo/>
            <NavLink to="/">  In Good Spirits</NavLink>
            
            <NavIcon onClick = {toggle}>
                <p>Menu</p>
                <Bars/>
            </NavIcon>
        </Nav>
    </>
  );
};

export default Navbar