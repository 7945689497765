import product1 from '../../images/Sixling2.png';
import product2 from '../../images/GunPowderGin_1.png';
import product3 from '../../images/GunPowderGin_2.png';
import product4 from '../../images/PalmaGin_1.png';
import product5 from '../../images/PalmaGin_2.png';
import product6 from '../../images/PalmaVodka.png';
import product7 from '../../images/RRLivelyWhite.png';
import product8 from '../../images/RRed.png';
import product9 from '../../images/RRWildRose.png';
import product10 from '../../images/RRDry.png';
import product11 from '../../images/Egan.png';
import product12 from '../../images/Coco1.png';
import product13 from '../../images/Coco2.png';
import product14 from '../../images/Fuba.png';
import product15 from '../../images/drumW.png';


export const productData = [
    {
      img: product2,
      alt: 'Gin',
      name: 'Gunpowder Irish Gin',
      desc:
        'Citrus and oriental spices, infused with Gunpowder tea',
      price: 'NOK 625',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Drumshanbo-Gunpowder-Irish-Gin/p/13084901"
    },
    {
      img: product3,
      alt: 'Gin',
      name: 'Gunpowder Irish Gin',
      desc:
        'Citrus and fresh, infused with Sardinian Citrus and Gunpowder tea',
      price: 'NOK 625',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Nettbutikk-kategorier/Brennevin/Gin/Drumshanbo-Gunpowder-Irish-Gin-Sardinian-Citrus/p/14408701"
    },{
      img: product1,
      alt: 'Gin',
      name: 'Sixling Irish Gin',
      desc:
        'Floral and herbal, infused with cloudberries "multebær"',
      price: 'NOK 630',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Sixling-Irish-Gin/p/13300901"
    }
  ];

  export const productDataTwo = [
    {
      img: product4,
      alt: 'Gin',
      name: 'Palma Gin',
      desc:
        'Fresh Mediterranean, with local Mallorcan botanicals',
      price: 'NOK 630',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Gin/p/13724701"
    },
    {
      img: product5,
      alt: 'Gin',
      name: 'Palma Spiced Gin',
      desc:
        'Spicy and sweet, matured with spices in oak casks',
      price: 'NOK 660',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Spiced-Gin/p/13725201"
    },
    {
      img: product6,
      alt: 'Palma Vodka',
      name: 'Palma Vodka',
      desc:
        'Smooth and peppery, infused with Mallorcan sea salt',
      price: 'NOK 625',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-vodka/p/13725701"
    }
  ];

  
  export const productDataThree = [
    {
      img: product13,
      alt: 'Latin',
      name: 'Cocalero Clasico',
      desc:
        'Coca leaf herbal spirit steam distilled with 17 botanicals',
      price: 'NOK 630',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Gin/p/13724701"
    },
    {
      img: product12,
      alt: 'Latin',
      name: 'Cocalero negro',
      desc:
        'Coca leaf herbal spirit with  Aji Panca chili and Locoto pepper',
      price: 'NOK 660',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Spiced-Gin/p/13725201"
    },
    {
      img: product14,
      alt: 'Latin',
      name: 'Fuba',
      desc:
        'Double distilled and smooth with hint of citrus',
      price: 'NOK 625',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-vodka/p/13725701"
    }
  ];

  export const productDataFour = [
    {
      img: product7,
      alt: 'Wine',
      name: 'RR Lively White',
      desc:
        'Chardonnay with local Lime Myrtle and Desert Lime',
      price: 'NOK 630',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Gin/p/13724701"
    },
    {
      img: product8,
      alt: 'Wine',
      name: 'RR Bold Red',
      desc:
        'Shiraz and  Chardonnay with Pepper berry and spices',
      price: 'NOK 660',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-Spiced-Gin/p/13725201"
    },
    {
      img: product9,
      alt: 'Wine',
      name: 'RR wild Rose',
      desc:
        'Cabernet Rose with Illawarra plums and Strawberry gum',
      price: 'NOK 625',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-vodka/p/13725701"
    },
    {
      img: product10,
      alt: 'Wine',
      name: 'RR Daring Dry',
      desc:
        'Sauvignon Blanc with Anise Myrtle and Quandong',
      price: 'NOK 625',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Spania/Palma-vodka/p/13725701"
    }
  ];

  export const productDataFive = [
   {
      img: product11,
      alt: 'Whiskey',
      name: 'Egan´s Fortitude Single Malt Irish Whiskey',
      desc:
        'Triple distilled and matured in Pedro Ximenez sherry casks ',
      price: 'NOK 630',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Egan%27s-Fortitude-Single-Malt/p/13726801"
    },
    {
      img: product15,
      alt: 'Whiskey',
      name: 'Drumshanbo Single Pot Still Irish Whiskey',
      desc:
        'Triple distilled by hand and matured in Oloroso sherry and ex bourbon casks',
      price: 'NOK 630',
      button: 'Order',
      link: "https://www.vinmonopolet.no/Land/Irland/Egan%27s-Fortitude-Single-Malt/p/13726801"
    }
  ];
