import styled from 'styled-components';
import {NavLink as Link} from 'react-router-dom';
import {BiWine} from 'react-icons/bi';
import Img from '../../images/LogoSvg.svg';

export const Nav = styled.nav`
  background: #191919;
  height: 80px;
  display: flex;
  justify-content: center;
  font-weight: 700;
`;

export const NavLink = styled(Link)`
  color: #fff;
  font-size: clamp(1.5rem, 2.5vw, 3rem);
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 400px) {
    font-size: clamp(1.5rem, 2.5vw, 3rem);
    position: relative;
    top: 10px;
    padding: 10px;
  }
`;

export const NavIcon = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;

  p {
    transform: translate(-175%, 100%);
    font-weight: bold;
    
  }

  @media screen and (max-width: 400px) {
    font-size: 1rem;
    position: absolute;
    top: 0;
    right: -20px;
  }
`;

export const Bars = styled(BiWine)`
  font-size: 2rem;
  transform: translate(-50%, -15%);
`;


export const Logo = styled.div`
  width: 60px;
  height: 80px;
  padding: 10px;
  margin-right: 10px;
  color: #fff;
  background: url(${Img}) 0 45% no-repeat;
  background-size: 100% 100%;
  cursor: pointer;

  @media screen and (max-width: 400px) {
   
    position: absolute;
   
    left: 25px;
  }
 
`;