import styled from 'styled-components/macro';


/*/ #150f0f vs #3d4c32 /*/



export const PagesContainer = styled.div`
  min-height: 90vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #191919;
  color: #fff;


@media screen and (max-width: 400px) {
   
  color:#ECDBBA;
   
 }


`
export const HeadingSection = styled.h1`
  margin-bottom: 24px;
  justify-content: center;
  text-align: center;
  font-size: clamp(2rem, 5vw, 5rem);
  font-weight: 600px;
  color: #ECDBBA;
 
`
export const Row1 = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  
   
`

export const TextContainer  = styled.p`
  text-align:justify;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 34px;
  justify-content:center;
  color: #fff;
  margin: 0 auto;
  padding: 1rem;
`

export const ImgWrap = styled.div`

  max-height: 400px;
  max-width: 560px;
  height: 100%;
  padding: 2rem;
`;

export const Img = styled.img`
  width: 100%;

`;