import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';

import './App.css';

import { GlobalStyle } from './globalStyles';

import Home from './components/pages';
import About from './components/pages/about';
import Spirit from './components/pages/spirits';
import Footer from './components/Footer';
import ScrollToTop from './components/Sidebar/ScrollToTop';


function App() {


  return (
   

      
      <Router>
        <ScrollToTop/>
        <GlobalStyle/>
        
        <Routes>
          
          <Route exact path="/" element={<Home/>} > </Route>
          <Route path='/About'  element={<About/>}  > </Route>
          <Route path='/Spirit'  element={<Spirit/>}> </Route>
        </Routes>
       
        <Footer/>
        </Router>
     
      
    
  );
}

export default App;
