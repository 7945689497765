import React,{useState} from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar';
import imgAbout from '../../images/Stand_about.jpeg';

import { PagesContainer, Row1, TextContainer,HeadingSection, ImgWrap,Img} from './PagesElements';


const About = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle= () =>{ setIsOpen(!isOpen);
    };


  return (
    <>
     <Navbar toggle={toggle}/>
    <Sidebar isOpen={isOpen} toggle={toggle}/>

        <PagesContainer>
        <HeadingSection>
                            About us 
        </HeadingSection>
            

                <Row1>
                    

                    <ImgWrap>
                    <Img src= {imgAbout} />

                    </ImgWrap>

                    <TextContainer>
                    In Good Spirits delivers premium gins, whiskies, vermouths and other niche spirits to Vinmonopolet and the HoReCa sector in Norway.
                    <br/>
                    <br/>
                    If you would like any further information on the products or what can be offered, please get in contact.
                    <br/>
                    <br/>
                    Contact: paul@ingoodspirits.no
                        
                    </TextContainer>

                    
                </Row1>

                
        </PagesContainer>

    </>
   
  )
}

export default About


