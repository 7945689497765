import styled from 'styled-components';
import ImgBg from '../../images/Drinks.jpg';
import { Link } from 'react-router-dom';


export const HeroContainer = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${ImgBg});
  height: 120vh;
  background-position: 70% ;
  background-size: cover;
`;

export const HeroContent = styled.div`
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
  
`;

export const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  max-height: 100%;
  padding: 0 2rem;
  width: 650px;
  color: #fff;
  
  line-height: 1;
  

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const HeroH1 = styled.h1`
  font-size: clamp(2rem, 10vw, 4rem);
  margin-bottom: 1rem;
  box-shadow: 3px 5px #ECDBBA;
  letter-spacing: 1px;
  font-weight:800;
  text-transform: uppercase;
`;

export const HeroP = styled.p`
  font-size: clamp(1.5rem, 2.5vw, 3rem);
  margin-bottom: 2rem;
  font-weight:400;
  
`;


export const HeroBtn = styled(Link)`
  background: #ECDBBA;
  white-space: nowrap;
  padding: 1rem 4rem;
  color: #2D4263;
  font-size: 1.4rem;
  font-weight:800;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  font-size: clamp(1rem, 1.25vw, 2rem);

  &:hover {
    transition: 0.2s ease-in-out;
    background: #fff;
    color: #C84B31;
  }
`;

