import React from 'react';
import { FeatureContainer, FeatureButton,Headerwrap} from './FeatureElements';

const Feature = ({img,HeadingText,Ptetxt}) => {
  return (

    <>
    <FeatureContainer current= {img}>
      <Headerwrap> {HeadingText}</Headerwrap>
      
      <p1>{Ptetxt}</p1>

      
      <FeatureButton to='/Spirit'> See details</FeatureButton>
    </FeatureContainer>

    </>
  );
};

export default Feature;
