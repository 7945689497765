import React from 'react';

import {
  FaEnvelope,
  FaInstagram,
  
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  Logo
} from './FooterElements';


const Footer = () => {
  return (
    <FooterContainer> 
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
             
                    <SocialLogo to="/">
                    <Logo/>
                      In Good Spirits AS</SocialLogo>
                    <SocialIcons>
                        <SocialIconLink href="https://www.instagram.com/ingoodspiritsnorway/" target="_blank"
                        aria-label="Instagram" rel="noopener noreferrer">
                            <FaInstagram /> <p>@ingoodspiritsnorway</p>
                        </SocialIconLink>
                    </SocialIcons>

                    <SocialIcons>
                        <SocialIconLink target="_blank"
                        aria-label="Envelope" rel="noopener noreferrer">
                            <FaEnvelope /> <p>paul@ingoodspirits.no </p>
                        </SocialIconLink>
                    </SocialIcons>

                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>


    </FooterContainer>
  )
}

export default Footer
